footer{
    margin-top:50px;
    margin-bottom:10px;
}

.card{
    box-shadow: 0 0 5px rgba(0,0,0,.25) ;

}

.avatar-container{
    max-width: 250px;
    margin: 0 auto;
}

.welcome{
	background: url('./assets/background_camping.jpg');
	/* Set a specific height */
	height: 100%;
	/* min-height: 500px; */

	/* Create the parallax scrolling effect */
	background-attachment: fixed;
	background-position: top center;
	background-repeat: no-repeat;
	background-size: cover;

	color:white;
	text-shadow: 5px 5px 5px rgba(0,0,0,1);
}

.avatar{
    border-radius: 50%;
    border:10px solid rgba(255,255,255,.5);
    box-shadow: 0 0 10px rgba(0,0,0,.25) ;
}

@media only screen and (max-width: 600px) {
    .avatar-container {
        max-width: 100%;
        text-align: center;
    }
  }